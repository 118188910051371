import { ProviderService } from '@healthhub/api-lib';

import adjustService from './adjust';

type GlobalWindow = Record<string, (...T: unknown[]) => void>;

export enum EventName {
  CLICK_APPOINTMENT_CANCEL = 'click_appointment_cancel',
  CLICK_APPOINTMENT_DECLINE = 'click_appointment_decline',
  CLICK_APPOINTMENT_MARK_AS_COMPLETE = 'click_appointment_mark_as_complete',
  CLICK_APPOINTMENT_NO_SHOW = 'click_appointment_no_show',
  CLICK_APPOINTMENT_ONGOING = 'click_appointment_ongoing',
  PROVIDER_COPY_REGISTRATION_FORM = 'provider_copy_registration_form',
  PROVIDER_COPY_HEALTHHUB_PAGE = 'provider_copy_healthhub_page',
  PROVIDER_DOWNLOAD_QR_CODE = 'provider_download_qr_code',
  ADD_NEW_PROVIDER = 'add_new_provider',
  ADD_NEW_PROVIDER_BRANCH = 'add_new_provider_branch',
  UPDATE_PROVIDER_PROFILE = 'update_new_provider',
  UPDATE_PROVIDER_BRANCH_PROFILE = 'update_new_provider_branch',
  ONBOARD_NEW_PROVIDER = 'onboard_new_provider',
  ONBOARD_NEW_BRANCH = 'onboard_new_branch',
  ADD_TAG_CATEGORY = 'add_tag_category',
  UPDATE_TAG_CATEGORY = 'update_tag_category',
  ADD_REGULAR_SCHEDULE = 'add_regular_schedule',
  ADD_SPECIFIC_SCHEDULE = 'add_specific_schedule',
  ADD_BLOCK_SCHEDULE = 'add_block_schedule',
  UPDATE_REGULAR_SCHEDULE = 'update_regular_schedule',
  UPDATE_SPECIFIC_SCHEDULE = 'update_specific_schedule',
  UPDATE_BLOCK_SCHEDULE = 'update_block_schedule',
  REMOVE_REGULAR_SCHEDULE = 'remove_regular_schedule',
  REMOVE_SPECIFIC_SCHEDULE = 'remove_specific_schedule',
  REMOVE_BLOCK_SCHEDULE = 'remove_block_schedule',
  CLICK_ADD_NOTE = 'click_add_note',
  CLICK_EDIT_NOTE = 'click_edit_note',
  CLICK_DELETE_NOTE = 'click_delete_note',
  CLICK_EXPORT_CLIENTS = 'click_export_clients',
  CLICK_ADD_CLIENT = 'click_add_client',
  CLICK_ADD_CLIENT_PAGE = 'click_add_client_page',
  CLICK_ADD_SERVICE = 'click_add_service',
  CLICK_ADD_SERVICE_PAGE = 'click_add_service_page',
  CLICK_APPOINTMENT_ADD_APPOINTMENT = 'click_appointment_add_appointment',
  CLICK_APPOINTMENT_FOLLOW_UP_APPOINTMENT = 'click_appointment_follow_up_appointment',
  CLICK_APPOINTMENT_ADD_APPOINTMENT_PAGE = 'click_appointment_add_appointment_page',
  CLICK_APPOINTMENT_ADD_SERVICE_IN_REVIEW = 'click_appointment_add_service_in_review',
  CLICK_APPOINTMENT_BACK_TO_HOME = 'click_appointment_back_to_home',
  CLICK_APPOINTMENT_BOOK_NOW = 'click_appointment_book_now',
  CLICK_APPOINTMENT_CONFIRM_APPOINTMENT = 'click_appointment_confirm_booking',
  CLICK_APPOINTMENT_CONFIRM_GROUP_APPOINTMENT = 'click_appointment_confirm_group_booking',

  CLICK_APPOINTMENT_MAKE_ANOTHER_APPOINTMENT = 'click_appointment_make_another_appointment',
  CLICK_APPOINTMENT_NEXT_STEP = 'click_appointment_next_step',
  CLICK_APPOINTMENT_PROCEED_WITH_BOOKING = 'click_appointment_proceed_with_booking',
  CLICK_APPOINTMENT_REGISTER_OR_LOGIN_TO_CONTINUE = 'click_appointment_register_or_login_to_continue',
  CLICK_APPOINTMENT_RESCHEDULE = 'click_appointment_reschedule',
  CLICK_APPOINTMENT_RESCHEDULE_IN_REVIEW = 'click_appointment_reschedule_in_review',
  CLICK_APPOINTMENT_SELECT_DATE = 'click_appointment_select_date',
  CLICK_APPOINTMENT_SELECT_TIME = 'click_appointment_select_time',
  CLICK_APPOINTMENT_CONFIRM_BOOKING = 'click_confirm_booking',
  CLICK_APPOINTMENT_CONFIRM_PAYMENT = 'click_confirm_payment',
  CLICK_CANCEL_APPOINTMENT_IN_HEADER = 'click_cancel_appointment_in_header',
  CLICK_DELETE_SERVICE = 'click_delete_service',
  CLICK_EDIT_SERVICE = 'click_edit_service',
  CLICK_EDIT_SERVICE_PAGE = 'click_edit_service_page',
  CLICK_EXPORT_APPOINTMENTS = 'click_export_appointments',
  CLICK_HIDE_SERVICE = 'click_hide_service',
  CLICK_HOME_QUICKLINK = 'click_home_quicklink',
  CLICK_REDIRECT_APPOINTMENTS_IN_HEADER = 'click_redirect_appointments_in_header',
  CLICK_REDIRECT_RECORDS_IN_HEADER = 'click_redirect_records_in_header',
  CLICK_REDIRECT_MESSAGES_IN_HEADER = 'click_redirect_messages_in_header',
  CLICK_REGISTER_OR_LOGIN = 'click_register_or_login',
  CLICK_RESEND_CODE_LOGIN = 'click_resend_code_login',
  CLICK_RESEND_CODE_REGISTRATION = 'click_resend_code_registration',
  CLICK_SAVE_PROVIDER_CLIENT_PROFILE = 'click_save_provider_client_profile',
  CLICK_SELECTED_SERVICE = 'click_selected_service',
  CLICK_SEND_SMS_OTP = 'click_send_sms_otp',
  CLICK_SUPPORT = 'click_support',
  CLICK_UNHIDE_SERVICE = 'click_unhide_service',
  CLICK_VIEW_APPOINTMENT = 'client_view_appointment',
  CLICK_VIEW_CLIENT = 'click_view_client',
  CLICK_VIEW_PROVIDER_CLIENT_PROFILE_APPOINTMENT = 'click_view_provider_client_profile_appointment',
  CLICK_VIEW_PROVIDER_DIRECTORY_SERVICE = 'click_view_provider_directory_service',
  CLICK_VIEW_SERVICE = 'click_view_service',
  CLICK_ADD_BRANCH = 'click_add_branch',
  CLICK_EDIT_BRANCH = 'click_edit_branch',
  CLICK_APPOINTMENT_EDIT_SERVICES = 'click_appointment_edit_services',
  DROPDOWN_PROFILE = 'dropdown_profile',
  EXPORT_APPOINTMENTS = 'export_appointments',
  SAVE_PROFILE = 'save_profile',
  SEARCH_APPOINTMENTS = 'search_appointments',

  ON_PAGE_LOAD = 'on_page_load',
  PROVIDER_PAGE_ON_LOAD = 'provider_page_on_load',
  APPOINTMENT_STEP_3_ON_LOAD = 'appointment_step_3_on_load',
  APPOINTMENT_REVIEW_ON_LOAD = 'appointment_review_on_load',

  // GA native events
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  SEARCH = 'search',
  VIEW_ITEM_LIST = 'view_item_list', // when list of services is viewed
  VIEW_ITEM = 'view_item', // when a service is viewed
  ADD_TO_CART = 'add_to_cart', // when a service is selected
  REMOVE_FROM_CART = 'remove_from_cart', // when a service is deselected
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
  PAYMENT_FAILED = 'payment_failed',

  // Mainly for Moengage
  CLICK_HEALTHHUB_BUTTON = 'click_healthhub_button',
  SEARCH_PROVIDER = 'search_provider',
  SUCCESSFUL_BOOKING = 'successful_booking',
  SUCCESSFUL_GROUP_BOOKING = 'successful_group_booking',
  ENTERED_WELLNESS_PASS_CODE = 'enter_wellness_pass',
  SHARE_PROVIDER = 'share_provider',
}

export function setAnalyticsUser(userId: string, userProperties: Record<string, any> = {}) {
  if ((window as unknown as GlobalWindow).gtag) {
    (window as unknown as GlobalWindow).gtag('set', { user_id: userId });
    (window as unknown as GlobalWindow).gtag('set', 'user_properties', userProperties);
  }

  if ((window as unknown as GlobalWindow).smartech) {
    (window as unknown as GlobalWindow).smartech('identify', userId);
  }
}

export function trackEvent(eventName: EventName, params?: unknown) {
  // google
  if ((window as unknown as GlobalWindow).gtag) {
    (window as unknown as GlobalWindow).gtag('event', eventName, params);
  }

  // moengage
  if ((window as unknown as GlobalWindow).Moengage) {
    (
      (window as unknown as GlobalWindow).Moengage as unknown as {
        track_event: (...any: (string | unknown)[]) => void;
      }
    ).track_event(eventName, params);
  }

  // adjust
  if (window as unknown as GlobalWindow) {
    const adjustParams = params && typeof params === 'object' ? params : {};
    adjustService.sendEvent(eventName, adjustParams as any);

    //smartech
    (window as unknown as GlobalWindow).smartech('dispatch', eventName, adjustParams);
  }
}

export function getProviderServiceAnalyticsProps(providerService?: ProviderService) {
  return {
    service_id: providerService?.id?.toString(),
    service_availability: providerService?.availability,
    service_name: providerService?.label,
    service_pricing_type: providerService?.priceType,
  };
}

export const ADJUST_EVENT_TOKENS: { [key in EventName]: string } = {
  [EventName.CLICK_APPOINTMENT_CANCEL]: 'ynjtl4',
  [EventName.CLICK_APPOINTMENT_DECLINE]: 'f1zt57',
  [EventName.CLICK_APPOINTMENT_MARK_AS_COMPLETE]: 'lt4pwr',
  [EventName.CLICK_APPOINTMENT_NO_SHOW]: 'y5z2gy',
  [EventName.CLICK_APPOINTMENT_ONGOING]: 'xigdm7',
  [EventName.PROVIDER_COPY_REGISTRATION_FORM]: '5qvn9m',
  [EventName.PROVIDER_COPY_HEALTHHUB_PAGE]: '7vmvf8',
  [EventName.PROVIDER_DOWNLOAD_QR_CODE]: 'phe2kj',
  [EventName.ADD_NEW_PROVIDER]: 'ce9wkt',
  [EventName.ADD_NEW_PROVIDER_BRANCH]: '7t21eh',
  [EventName.UPDATE_PROVIDER_PROFILE]: 'b03bwo',
  [EventName.UPDATE_PROVIDER_BRANCH_PROFILE]: 'ag4poh',
  [EventName.ONBOARD_NEW_PROVIDER]: '1zxubd',
  [EventName.ONBOARD_NEW_BRANCH]: '299fu0',
  [EventName.ADD_TAG_CATEGORY]: 'reta3o',
  [EventName.UPDATE_TAG_CATEGORY]: 'taelbh',
  [EventName.ADD_REGULAR_SCHEDULE]: '8fsjkm',
  [EventName.ADD_SPECIFIC_SCHEDULE]: '181kjx',
  [EventName.ADD_BLOCK_SCHEDULE]: 'qe0krj',
  [EventName.UPDATE_REGULAR_SCHEDULE]: '2t3ku7',
  [EventName.UPDATE_SPECIFIC_SCHEDULE]: 'b6ur8l',
  [EventName.UPDATE_BLOCK_SCHEDULE]: 'fuwuvg',
  [EventName.REMOVE_REGULAR_SCHEDULE]: 'aqhnx2',
  [EventName.REMOVE_SPECIFIC_SCHEDULE]: '8su6xk',
  [EventName.REMOVE_BLOCK_SCHEDULE]: '3blg8w',
  [EventName.CLICK_ADD_NOTE]: 'ibqelh',
  [EventName.CLICK_EDIT_NOTE]: '1af8io',
  [EventName.CLICK_DELETE_NOTE]: 'j1skap',
  [EventName.CLICK_EXPORT_CLIENTS]: 'em20ch',
  [EventName.CLICK_ADD_CLIENT]: 'voep4f',
  [EventName.CLICK_ADD_CLIENT_PAGE]: 'pyjwhu',
  [EventName.CLICK_ADD_SERVICE]: 'xopspp',
  [EventName.CLICK_ADD_SERVICE_PAGE]: 'ilnmss',
  [EventName.CLICK_APPOINTMENT_ADD_APPOINTMENT]: 'vw6lgw',
  [EventName.CLICK_APPOINTMENT_FOLLOW_UP_APPOINTMENT]: 'u1754f',
  [EventName.CLICK_APPOINTMENT_ADD_APPOINTMENT_PAGE]: 'b7cw52',
  [EventName.CLICK_APPOINTMENT_ADD_SERVICE_IN_REVIEW]: 's64bk3',
  [EventName.CLICK_APPOINTMENT_BACK_TO_HOME]: 'xzzhsy',
  [EventName.CLICK_APPOINTMENT_BOOK_NOW]: 'b1fnr7',
  [EventName.CLICK_APPOINTMENT_CONFIRM_APPOINTMENT]: 'waumhs',
  [EventName.CLICK_APPOINTMENT_CONFIRM_GROUP_APPOINTMENT]: 'iffad8',
  [EventName.CLICK_APPOINTMENT_MAKE_ANOTHER_APPOINTMENT]: 'v85sx4',
  [EventName.CLICK_APPOINTMENT_NEXT_STEP]: 'wl15ol',
  [EventName.CLICK_APPOINTMENT_PROCEED_WITH_BOOKING]: '93ewui',
  [EventName.CLICK_APPOINTMENT_REGISTER_OR_LOGIN_TO_CONTINUE]: '74rgnl',
  [EventName.CLICK_APPOINTMENT_RESCHEDULE]: '5acr7l',
  [EventName.CLICK_APPOINTMENT_RESCHEDULE_IN_REVIEW]: 'bxrvr0',
  [EventName.CLICK_APPOINTMENT_SELECT_DATE]: '6j6uve',
  [EventName.CLICK_APPOINTMENT_SELECT_TIME]: 'odtvaa',
  [EventName.CLICK_APPOINTMENT_CONFIRM_BOOKING]: 'w8mkbw',
  [EventName.CLICK_APPOINTMENT_CONFIRM_PAYMENT]: '2v77z7',
  [EventName.CLICK_CANCEL_APPOINTMENT_IN_HEADER]: 'lf6ccx',
  [EventName.CLICK_DELETE_SERVICE]: 'q5xzqv',
  [EventName.CLICK_EDIT_SERVICE]: 'xbm7cq',
  [EventName.CLICK_EDIT_SERVICE_PAGE]: 'hocvzq',
  [EventName.CLICK_EXPORT_APPOINTMENTS]: 'ojvg90',
  [EventName.CLICK_HIDE_SERVICE]: 'pxpfdy',
  [EventName.CLICK_HOME_QUICKLINK]: '8h81hw',
  [EventName.CLICK_REDIRECT_APPOINTMENTS_IN_HEADER]: 'lwz4ta',
  [EventName.CLICK_REDIRECT_RECORDS_IN_HEADER]: '6ly7s3',
  [EventName.CLICK_REDIRECT_MESSAGES_IN_HEADER]: '8p0pw6',
  [EventName.CLICK_REGISTER_OR_LOGIN]: 'gduwws',
  [EventName.CLICK_RESEND_CODE_LOGIN]: 'lk8kch',
  [EventName.CLICK_RESEND_CODE_REGISTRATION]: '6h4irb',
  [EventName.CLICK_SAVE_PROVIDER_CLIENT_PROFILE]: 'eomyqz',
  [EventName.CLICK_SELECTED_SERVICE]: '80dusb',
  [EventName.CLICK_SEND_SMS_OTP]: '5gqn5j',
  [EventName.CLICK_SUPPORT]: 'vzruii',
  [EventName.CLICK_UNHIDE_SERVICE]: 'cce3uf',
  [EventName.CLICK_VIEW_APPOINTMENT]: '4lpmrj',
  [EventName.CLICK_VIEW_CLIENT]: '4bnf30',
  [EventName.CLICK_VIEW_PROVIDER_CLIENT_PROFILE_APPOINTMENT]: 'isey34',
  [EventName.CLICK_VIEW_PROVIDER_DIRECTORY_SERVICE]: '6s2kij',
  [EventName.CLICK_VIEW_SERVICE]: 'gpn22j',
  [EventName.CLICK_ADD_BRANCH]: 'd5kzzx',
  [EventName.CLICK_EDIT_BRANCH]: 'xdcdsn',
  [EventName.CLICK_APPOINTMENT_EDIT_SERVICES]: 'cjjiqp',
  [EventName.DROPDOWN_PROFILE]: '7zm3z7',
  [EventName.EXPORT_APPOINTMENTS]: 'ojvg90',
  [EventName.SAVE_PROFILE]: 'bosjhr',
  [EventName.SEARCH_APPOINTMENTS]: 'r5oqo2',
  [EventName.LOGIN]: '31h5d9',
  [EventName.SIGN_UP]: 'di55ke',
  [EventName.SEARCH]: '8ofi7p',
  [EventName.VIEW_ITEM_LIST]: 'dikrqq',
  [EventName.VIEW_ITEM]: 'bat9tb',
  [EventName.ADD_TO_CART]: '8orcs7',
  [EventName.REMOVE_FROM_CART]: '1utvj1',
  [EventName.BEGIN_CHECKOUT]: '1gflyu',
  [EventName.PURCHASE]: '7yrcyl',
  [EventName.PAYMENT_FAILED]: '6cg0c2',
  [EventName.CLICK_HEALTHHUB_BUTTON]: '65snpv',
  [EventName.SEARCH_PROVIDER]: 'q5kkzv',
  [EventName.SUCCESSFUL_BOOKING]: 'qya3uv',
  [EventName.SUCCESSFUL_GROUP_BOOKING]: '1xlnhr',
  [EventName.ENTERED_WELLNESS_PASS_CODE]: '8voot1',
  [EventName.SHARE_PROVIDER]: 'gb7d4y',
  [EventName.ON_PAGE_LOAD]: '92ipgp',
  [EventName.PROVIDER_PAGE_ON_LOAD]: 'o6a7rf',
  [EventName.APPOINTMENT_STEP_3_ON_LOAD]: 'aqzdx5',
  [EventName.APPOINTMENT_REVIEW_ON_LOAD]: 'qsuivk',
};
